<template>
    <div>
        <Pane />

        <a-card class="container">

            <a-row>
                <a-col :span="22">

                    <a-form-model layout="inline" :colon="false" :model="form" @keyup.enter.native="query">
                        <a-form-model-item>
                            <a-input placeholder="合同编号" v-model="form.contractCode" style="width: 150px">
                            </a-input>
                        </a-form-model-item>

                        <a-form-model-item>
                            <a-input placeholder="合同名称" v-model="form.contractName" style="width: 150px"></a-input>
                        </a-form-model-item>

                        <a-form-model-item>
                            <a-space>
                                <a-button @click="query" type="primary">查询</a-button>
                                <a-button @click="reset">重置</a-button>
                            </a-space>
                        </a-form-model-item>
                    </a-form-model>
                </a-col>
                <a-col :span="2">
                    <div class="right" style="margin-top: 4px">
                        <a-button v-if="$getPermission($route.path + '/add')" @click="$router.push($route.path + '/add')"
                            type="primary">新增</a-button>
                    </div>
                </a-col>
            </a-row>

            <Padding />

            <a-table bordered :data-source="list" :loading="loading" @change="onChange" :pagination="{
                total,
                current,
                pageSize,
                showTotal: (total) => `共 ${total} 条记录`,
            }" rowKey="id">

                <a-table-column title="序号" width="50px" align="center" fixed="left">
                    <template slot-scope="text, row, index">
                        {{ index + 1 }}
                    </template>
                </a-table-column>

                <a-table-column title="合同编号" width="120px" data-index="contractCode" fixed="left" />
                <a-table-column title="合同名称" width="400px" data-index="contractName" />

                <a-table-column title="预计总成本(元)" align="right">
                    <template slot-scope="text">
                        <span style="color: #1890ff">
                            {{ typeof text.estTotalAmt === 'number' ? text.estTotalAmt.toLocaleString() : text.estTotalAmt
                            }}
                        </span>
                    </template>
                </a-table-column>

                <a-table-column title="利润(元)" align="right">
                    <template slot-scope="text">
                        <span style="color: #1890ff">
                            {{ typeof text.profit === 'number' ? text.profit.toLocaleString() : text.profit }}
                        </span>
                    </template>
                </a-table-column>

                <a-table-column title="项目进度" align="center">
                    <template slot-scope="text">
                        <a-progress :percent="typeof text.progress === 'number' ? text.progress : 0" />
                    </template>
                </a-table-column>

                <a-table-column title="更新状态" align="center">
                    <template slot-scope="text">
                        <span v-if="text.updateStatus">
                            <a-badge status="success" text="已更新" />
                        </span>
                        <span v-if="text.updateStatus === false">
                            <a-badge status="error" text="未更新" />

                        </span>
                    </template>
                </a-table-column>



                <a-table-column align="right" width="240px" fixed="right" title="操作">
                    <template slot-scope="text">
                        <a-space>
                            <a href="#" v-if="$getPermission($route.path + '/edit')"
                                @click.prevent="openDetail(text)">预计总成本详情</a>

                            <a href="#" v-if="$getPermission($route.path + '/edit')"
                                @click.prevent="openProgress(text)">进度详情</a>

                            <a href="#" v-if="$getPermission($route.path + '/edit')" @click.prevent="edit(text)">修改</a>

                            <a href="#" class="danger" v-if="$getPermission($route.path + '/delete')"
                                @click.prevent="deleteText(text)">删除</a>
                        </a-space>
                    </template>
                </a-table-column>
            </a-table>

            <Progress :visible="visible" :selected="selected" @cancel="cancel" />
        </a-card>

    </div>
</template>
  
<script>
import watermark from "@/mixins/watermark";
import Progress from './components/progress.vue'
import {
    fetchList,
    remove,
} from "./api";
export default {

    components: {
        Progress,
    },

    name: "epcProjectSummary",

    mixins: [watermark],

    data() {
        return {
            form: {},

            loading: false,
            current: 1,
            pageSize: 10,
            list: [],
            total: 0,

            selected: {},
            visible: false,
        };
    },

    activated() {
        this.getList();
    },

    methods: {
        getList() {
            const { current, pageSize } = this;
            this.loading = true;
            fetchList({
                pageNum: current,
                pageSize: pageSize,
                ...this.form,
            })
                .then((res) => {
                    if (Array.isArray(res.list)) {
                        this.list = Object.freeze(res.list);
                        this.total = res.totalSize || 0;
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        onChange(pagination) {
            this.current = pagination.current;
            this.pageSize = pagination.pageSize;
            this.getList();
        },

        query() {
            this.current = 1;
            this.getList();
        },
        reset() {
            this.form = {};
            this.current = 1;
            this.getList();
        },

        deleteText(text) {
            const that = this;
            this.$confirm({
                title: "确认要删除吗？",
                onOk() {
                    remove({
                        id: text.id,
                    }).then(() => {
                        that.getList();
                    });
                },
            });
        },

        openDetail(text) {
            this.$router.push(this.$route.path + '/detail?id=' + text.id)
        },
        edit(text) {
            this.$router.push(this.$route.path + '/edit?id=' + text.id)
        },

        openProgress(text) {
            this.selected = text;
            this.visible = true;
        },
        cancel() {
            this.visible = false;
            this.selected = {}
        }

    },
};
</script>