<template>
    <a-modal title="进度详情" :visible="visible" @cancel="cancel" :footer="null" width="640px">

        <a-descriptions bordered size="small" :column="1">

            <a-descriptions-item>
                <div slot="label" class="center">合同编号</div>
                <div>
                    {{ selected.contractCode }}
                </div>
            </a-descriptions-item>
            <a-descriptions-item>
                <div slot="label" class="center">合同名称</div>
                <div>
                    {{ selected.contractName }}
                </div>
            </a-descriptions-item>
        </a-descriptions>

        <Padding size="large" />

        <a-table bordered :data-source="list" :loading="loading" :pagination="false">

            <a-table-column title="序号" width="50px" align="center">
                <template slot-scope="text, row, index">
                    {{ index + 1 }}
                </template>
            </a-table-column>

            <a-table-column title="月份" data-index="date" />

            <a-table-column title="完成进度" align="center">
                <template slot-scope="text">
                    <a-progress :percent="typeof text.progress === 'number' ? parseInt(text.progress) : 0" />
                </template>
            </a-table-column>

            <a-table-column title="确认营收(元)" align="right">
                <template slot-scope="text">
                    <span style="color: #1890ff">
                        {{ typeof text.income === 'number' ? text.income.toLocaleString() : text.income }}
                    </span>
                </template>
            </a-table-column>

            <a-table-column title="利润(万)" align="right">
                <template slot-scope="text">
                    <span style="color: #1890ff">
                        {{ typeof text.profit === 'number' ? text.profit.toLocaleString() : text.prdictCost }}
                    </span>
                </template>
            </a-table-column>


        </a-table>

    </a-modal>
</template>

<script>
import { fetchProgress } from '../api'
export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        selected: {
            type: Object,
        }
    },

    data() {
        return {
            list: [],
            loading: false,
        }
    },

    watch: {
        visible() {
            if (this.visible && this.selected.contractCode) {
                this.loading = true;
                fetchProgress({
                    code: this.selected.contractCode
                }).then(res => {
                    console.log('res', res)
                    if (Array.isArray(res)) {
                        this.list = res;
                    }
                }).finally(() => {
                    this.loading = false;
                })
            }
        }
    },

    methods: {
        cancel() {
            this.$emit('cancel')
        }
    }
}
</script>