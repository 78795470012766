import request from '@/api/request'

export function fetchList(data) {
    return request({
        url: '/market-service/epc/prg/prj/list',
        data,
        method: "post",
    })
}

export function add(data) {
    return request({
        url: '/market-service/epc/prg/prj/add',
        data,
        method: "post",
    })
}

export function update(data) {
    return request({
        url: '/market-service/epc/prg/prj/update',
        data,
        method: "post",
    })
}

export function fetchDetail(data) {
    return request({
        url: '/market-service/epc/prg/prj/query/' + data.id,
    })
}

export function fetchProgress(params) {
    return request({
        url: '/market-service/epc/prg/prj/querySumByCode',
        params,
    })
}

export function remove(data) {
    return request({
        url: '/market-service/epc/prg/prj/delete',
        data,
        method: "post",
    })
}